import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../ui/dialog";
import { TypographyBody, TypographyH4 } from "../ui/Typography";
import { FileSearch, Newspaper, Sparkles } from "lucide-react";

type NotAvailableDialogVariant = 'doc-analysis' | 'news' | 'docgen'

interface NotAvailableDialogProps {
    open: boolean
    setOpen: (v: boolean) => void
    variant: NotAvailableDialogVariant
}

export const NotAvailableDialog = ({ open, setOpen, variant }: NotAvailableDialogProps) => {
    const getIcon = () => {
        switch (variant) {
            case 'doc-analysis': return <FileSearch className="w-8 h-8 shrink-0 stroke-[1.5px]" />
            case 'news': return <Newspaper className="w-8 h-8 shrink-0 stroke-[1.5px]" />
            case 'docgen': return <Sparkles className="w-8 h-8 shrink-0 stroke-[1.5px]" />
        }
    }

    const getTitle = () => {
        switch (variant) {
            case 'doc-analysis': return 'Document analysis is coming soon'
            case 'news': return 'News are coming soon'
            case 'docgen': return 'Document generation is coming soon'
        }
    }

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="border-0 shadow-none">
                <DialogTitle className="relative h-8 mb-2">
                    <div className="absolute left-[calc(50%-16px)]">
                        {getIcon()}
                    </div>
                </DialogTitle>
                <DialogDescription className="text-center">
                    <div className="flex flex-col gap-2">
                        <TypographyH4 className="text-system-primary">
                            {getTitle()}
                        </TypographyH4>
                        <TypographyBody className="text-system-body">
                            We are working hard to bring this feature to the platform. We will let you know once ready.
                        </TypographyBody>

                    </div>
                </DialogDescription>
            </DialogContent>
        </Dialog>
    )
}