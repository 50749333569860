import "./DocumentList.css"
import { useContext, useEffect, useMemo, useState } from "react";
import { IDocumentStore, IDocumentStoreWithHandlers, DocumentStoreContext, sortDocumentsFn } from "../contexts/DocumentContext";
import { ResponseDocument } from "../types/types";
import { QueryStatus } from "../types/types";
import attachFileIcon from "../assets/add-outline-svgrepo-com.svg";
import { useReactTable, createColumnHelper, getCoreRowModel, flexRender } from '@tanstack/react-table';
import refreshIcon from "../assets/refresh-cw-alt-3-svgrepo-com.svg";
import { CircularProgress } from "./CircularProgress";
import { UserContext } from "../contexts/UserContext";
import { getFileLink } from "../utils/utils";
import { ErrorMessage } from "./ErrorMessage";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { ResourcesPageContainer } from "./Resources/ResourcesPage";

interface Props {
    documentStore: IDocumentStoreWithHandlers;
}

type UploadedBy = 'anyone' | 'me' | 'desia'
type FileFilterOptions = {
    uploadedBy: UploadedBy,
}

export function DocumentList({ documentStore }: Props) {
    const [fileFilter, setFileFilter] = useState<FileFilterOptions>({
        uploadedBy: 'anyone'
    });
    const { user } = useContext(UserContext);

    function shouldRefetchDocumentList(store: IDocumentStore) {
        if ([QueryStatus.INITIALISED].includes(store.status)) return true;
        return false;
    }

    async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.currentTarget.files) return;
        const [file] = e.currentTarget.files;
        if (!file) return;
        documentStore.handlers.uploadDocument(file);
    }

    const columnHelper = createColumnHelper<ResponseDocument>();
    const columns = useMemo(() => [
        columnHelper.accessor('document_name', {
            cell: info => <span onClick={() => {
                const url = getFileLink(info.row.original.document_id, info.row.original.document_name);
                window.open(url, "_blank");
            }}>{info.getValue()}</span>,
            footer: info => info.column.id,
            header: "Name",
        }),
        columnHelper.accessor("document_processing_status", {
            cell: info => {
                if (info.row.getValue("document_is_ready_to_use") === true) {
                    return <span className="documentlist-status-text">✅ Ready</span>
                }
                const status = info.row.getValue("document_processing_status") as string;
                return <span className="documentlist-status-text">🤖 {status}</span>
            },
            footer: info => info.column.id,
            header: "Status",
        }),
        columnHelper.accessor('document_type_friendly', {
            cell: info => {
                const fileType = info.row.getValue("document_type_friendly");
                if (fileType === "Unknown") {
                    return info.row.getValue("document_type");
                }
                return fileType;
            },
            footer: info => info.column.id,
            header: "Type",
        }),
        columnHelper.accessor('created_at_desia', {
            cell: info => new Date(info.getValue()).toLocaleDateString(),
            footer: info => info.column.id,
            header: "Created At",
        }),
        columnHelper.accessor('document_size', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
            header: "Size",
        }),
        columnHelper.accessor('document_type', {
            cell: info => {
                const fileType = info.row.getValue("document_type_friendly");
                if (fileType === "Unknown") {
                    return info.row.getValue("document_type");
                }
                return fileType;
            },
            footer: info => info.column.id,
            header: "Doc Type",
        }),
        columnHelper.accessor('document_is_ready_to_use', {
            cell: info => info.getValue(),
            footer: info => info.column.id,
        })
    ], []);


    const isFetching = documentStore.store.status === QueryStatus.FETCHING;
    const errorFetching = documentStore.store.status === QueryStatus.ERROR_FETCHING;
    const errorUploading = documentStore.store.status === QueryStatus.ERROR_UPLOADING;

    const filteredFiles: ResponseDocument[] = useMemo(() => {
        return (documentStore.store.data || []).filter(f => {
            if (fileFilter.uploadedBy === 'me') {
                return f.created_by_oauth_user_id === user?.sub;
            }
            if (fileFilter.uploadedBy === 'desia') {
                return f.document_is_part_of_desia_library === true;
            }
            return f;
        });
    }, [documentStore.store, fileFilter]);

    const table = useReactTable({
        data: filteredFiles,
        columns,
        initialState: {
            columnVisibility: {
                "document_type": false,
                "document_is_ready_to_use": false,
            }
        },
        getCoreRowModel: getCoreRowModel(),
    });

    function selectedTabClass(uploadedBy: UploadedBy) {
        if (uploadedBy === fileFilter.uploadedBy) return "selected-tab";
        return "";
    }

    function getSelectedTabName() {
        switch (fileFilter.uploadedBy) {
            case "desia":
                return "Desia Library";
            case "me":
                return "Uploaded By Me";
            case "anyone":
                return "All Files";
            default:
                return "All Files";
        }
    }
    const selectedTabName = getSelectedTabName();

    useEffect(() => {
        if (shouldRefetchDocumentList(documentStore.store)) {
            documentStore.handlers.listDocuments();
        }
    }, []);

    return (
        <div className="document-list">
            <div className="filters">
                <h1 className="title font-h1">Resources</h1>
            </div>
            <div className="file-type-tabs">
                <div className={`file-type-tab ${selectedTabClass("anyone")}`} onClick={() => {
                    setFileFilter({ uploadedBy: 'anyone' })
                }}>
                    All Files
                </div>

                <div className={`file-type-tab ${selectedTabClass("me")}`} onClick={() => {
                    setFileFilter({ uploadedBy: 'me' })
                }}>
                    Uploaded
                </div>

                <div className={`file-type-tab ${selectedTabClass("desia")}`} onClick={() => {
                    setFileFilter({ uploadedBy: 'desia' })
                }}>
                    Desia Library
                </div>
            </div>

            <div className="actions">
                <div className="refresh-action">
                    <h3 className="documentlist-subtitle font-h3">{selectedTabName}</h3>
                    <button className="refresh-button" onClick={documentStore.handlers.listDocuments}
                        disabled={isFetching}
                    ><img src={refreshIcon} className="refresh-icon" /></button>
                </div>

                <label className="custom-file-upload icon-button icon-button-dark">
                    <input id="file" type="file" name="file" onChange={handleFileChange} className="hidden-file-input" />
                    <img aria-label='upload file icon' src={attachFileIcon} className='icon light' />
                    <span>Add Files</span>
                </label>

            </div>

            <div>
                {isFetching && (
                    <div className="skeleton document-skeleton" />
                )}

                {!isFetching && errorFetching && (
                    <ErrorMessage message={`We failed to list documents`} />
                )}

                {!isFetching && errorUploading && (
                    <ErrorMessage message={`We failed to upload that document`} />
                )}

                {!isFetching && !errorFetching && <table className="document-table">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id} className="header-row tr">
                                {headerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {documentStore.store.fileUpload.map(file => {
                            return (
                                <tr key={`file-upload-${file.name}`}>
                                    <td className="uploading-td"><CircularProgress className={"circular-progress"} /> {file.name}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )
                        })}
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} className={cell.column.id === "document_name" ? "filename td" : "td"}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
        </div>
    )
}

export function DocumentListContainer() {
    const store = useContext(DocumentStoreContext);
    const ff = useContext(FeatureFlagContext);
    if (ff.checkFlag("ui: legacy design system")) {
        return (
            <DocumentList documentStore={store} />

        )
    }
    return (
        <ResourcesPageContainer sortDocumentsFn={sortDocumentsFn}/>
    )
}
