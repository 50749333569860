import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import { Loader2 } from "lucide-react"
import { TypographyBody } from "./ui/Typography"
import Markdown from "markdown-to-jsx"
import { useState } from "react"
import { ExpertModeDocument } from "@/types/types"
import { PreviewSources } from "./Assistant/PreviewSources"
import { mapPlannerDocumentToSourceDocument } from "@/utils/utils"
import { ErrorMessage } from "./ErrorMessage"
import Checkmark from "@/assets/Checkmark"

function getDocuments(step: PlannerStep, plannerDocuments: Record<string, ExpertModeDocument[]> | null) {
    try {
        return (step.doc_ref && plannerDocuments) ? (plannerDocuments[step.doc_ref] || []).map(mapPlannerDocumentToSourceDocument) : [];
    } catch (e) {
        console.error(e);
        return [];
    }
}

type PlannerStep = {
    description: string,
    tool: string[], // todo: stronger typing / name tools
    answer: string | null,
    doc_ref: string | null,
}

export type PlannerStepsProps = {
    plannerSteps: PlannerStep[];
    plannerDocuments: Record<string, ExpertModeDocument[]> | null;
}

export function PlannerSteps({ plannerSteps, plannerDocuments }: PlannerStepsProps) {
    const planDefined = plannerSteps.length > 0;
    const nextStep = plannerSteps.findIndex(s => !s.answer);
    const pendingClasses = "text-system-placeholder";
    const firstStep = "plannerstep_0";
    const [accordionValue, setAccordionValue] = useState({ value: firstStep, toggled: false })
    function handleShow(value: string) {
        setAccordionValue({ value, toggled: true });
    }
    const autoShowItemValue = accordionValue.toggled ? (accordionValue.value) : (nextStep === -1 ? `plannerstep_${plannerSteps.length}` : `plannerstep_${nextStep}`);

    return (
        <div className="border border-indigo-60 p-4 rounded-md bg-system-secondary">
            <Accordion type="single" collapsible value={autoShowItemValue} onValueChange={handleShow} className="flex flex-col gap-2">
                <AccordionItem value="plannerstep_0" className="border-none">
                    <AccordionTrigger>
                        <div className="flex gap-2 content-center items-start text-left m-0">
                            {planDefined && (
                                <>
                                    <Checkmark className="h-6 w-6" />
                                    <TypographyBody isStrong={true}>
                                        Planned
                                    </TypographyBody>
                                </>
                            )}

                            {!planDefined && (
                                <>
                                    <Loader2 className="h-5 w-5 mt-0.5 mx-0.5 animate-spin flex-shrink-0" />
                                    <TypographyBody isStrong={true}>
                                        Planning
                                    </TypographyBody>
                                </>
                            )}

                        </div>
                    </AccordionTrigger>
                    <AccordionContent className="ml-8 font-label text-system-body mt-2">
                        {!planDefined && "AI agents defining a plan to best answer your questions."}
                        {planDefined && "Defined a plan of action. AI agents are answering your question."}
                    </AccordionContent>
                </AccordionItem>
                {plannerSteps.map((s, idx) => {
                    const accordionValue = `plannerstep_${idx + 1}`;

                    try {
                        const isActive = nextStep === idx;
                        const isComplete = Boolean(s.answer);
                        const isPending = !isActive && !isComplete;
                        const documents = getDocuments(s, plannerDocuments);

                        if (isComplete) {
                            return (
                                <AccordionItem key={`step_${idx}`} value={accordionValue} className="border-none">
                                    <AccordionTrigger>
                                        <div className="flex gap-2 content-start items-start text-left border-none">
                                            {isActive && <Loader2 className="h-5 w-5 mt-0.5 mx-0.5 animate-spin flex-shrink-0" />}
                                            {isComplete && <Checkmark className="h-6 w-6 flex-shrink-0" />}
                                            {isPending && <div className={`h-1.5 w-1.5 mt-[9px] rounded-full mx-[9px] shrink-0 bg-system-placeholder`}></div>}
                                            <span className={`${isPending ? pendingClasses : ""}`}>
                                                <TypographyBody isStrong={true}>
                                                    {s.description}
                                                </TypographyBody>
                                            </span>
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent className="ml-8 font-label text-system-body mt-2">
                                        <div className="flex flex-col gap-4">
                                            {documents.length > 0 && (
                                                <div className="">
                                                    <PreviewSources documents={documents} />
                                                </div>
                                            )}
                                            <Markdown>
                                                {s.answer || ""}
                                            </Markdown>
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            )
                        }

                        return (
                            <AccordionItem key={`step_${idx}`} value={accordionValue} className="border-none">
                                <div className="flex gap-2 content-start items-start text-left">
                                    {isActive && <Loader2 className="h-5 w-5 mt-0.5 mx-0.5 animate-spin flex-shrink-0" />}
                                    {isComplete && <Checkmark className="h-6 w-6 flex-shrink-0" />}
                                    {isPending && <div className={`h-1.5 w-1.5 mt-[9px] rounded-full mx-[9px] shrink-0 bg-system-placeholder`}></div>}
                                    <span className={`${isPending ? pendingClasses : ""}`}>
                                        <TypographyBody isStrong={true}>
                                            <Markdown>
                                                {s.description}
                                            </Markdown>
                                        </TypographyBody>
                                    </span>
                                </div>
                            </AccordionItem>
                        )

                    } catch (e) {
                        console.error(e);
                        return (
                            <AccordionItem value={accordionValue}>
                                <ErrorMessage message="We failed to answer this step." />
                            </AccordionItem>
                        )
                    }
                })}
            </Accordion>
        </div>
    )
}