import { FileStructure, ResponseDocument } from "@/types/types";
import { convertUUIDToNumber, getCreatedAt, getUpdatedAt } from "./utils";

export const sortFileStructures = (a: FileStructure, b: FileStructure, resources: ResponseDocument[], structures: FileStructure[]) => {
    const aChildren = (a.children_element_internal_ids || []).length > 0 ? getAllFileChildren(structures, a.internal_element_id) : [a]
    const bChildren = (b.children_element_internal_ids || []).length > 0 ? getAllFileChildren(structures, b.internal_element_id) : [b]

    const aChildrenDocuments: ResponseDocument[] = aChildren.map((c) => resources?.find((v) => v.document_id === c.document_id)).filter((v): v is ResponseDocument => Boolean(v))
    const bChildrenDocuments: ResponseDocument[] = bChildren.map((c) => resources?.find((v) => v.document_id === c.document_id)).filter((v): v is ResponseDocument => Boolean(v))

    const aProcessing = aChildrenDocuments.some((v) => !v.document_is_ready_to_use)
    const bProcessing = bChildrenDocuments.some((v) => !v.document_is_ready_to_use)

    if (aProcessing !== bProcessing) {
        return bProcessing ? 1 : -1;
    }

    const aDate = getFileChildrenLatestDate(aChildrenDocuments)
    const bDate = getFileChildrenLatestDate(bChildrenDocuments)

    return (bDate?.getTime() || 0) - (aDate?.getTime() || 0)
}

export const getAllFileChildren = (data: FileStructure[], parentId: number) => {
    const parent = data.find(item => item.internal_element_id === parentId);
    if (!parent) return [];
    let children: FileStructure[] = [];
    parent.children_element_internal_ids?.forEach(childId => {
        const child = data.find(item => item.internal_element_id === childId && item.parent_element_internal_ids.length > 0);

        if (child) {
            children.push(child);
            children = [...children, ...getAllFileChildren(data, childId)];
        }
    });

    return children;
}

export const getFileChildrenLatestDate = (r: ResponseDocument[]): Date | null => {
    const lastUpdated = r.reduce((latest, current) => {
        const currentDate = getUpdatedAt(current) || getCreatedAt(current)
        const latestDate = getUpdatedAt(latest) || getCreatedAt(latest)

        if (!latestDate) return current
        if (!currentDate) return latest

        return currentDate > latestDate ? current : latest;
    }, r[0])

    return getUpdatedAt(lastUpdated) || getCreatedAt(lastUpdated)
}

export const reduceResponseDocuments = (resources: ResponseDocument[]): FileStructure[] => {
    const elements: FileStructure[] = []

    resources.forEach((r) => {
        (r.document_source_path_treeview || []).forEach((e) => {
            if (!elements.find((v) => v.internal_element_id === e.internal_element_id)) {
                elements.push({
                    internal_element_id: e.internal_element_id,
                    document_id: e.element_type === 'file' ? r.document_id : undefined,
                    element_type: e.element_type,
                    element_name: e.element_name,
                    parent_element_internal_ids: e.parent_element_internal_ids,
                    children_element_internal_ids: e.children_element_internal_ids,
                    is_included: false,
                    child_is_included: false,
                    is_excluded: false,
                    child_is_excluded: false
                } as FileStructure)
            }
        })
    })

    return elements
}

export const transformResponseDocuments = (resources: ResponseDocument[]) => {
    const elements = reduceResponseDocuments(resources)

    const mappedResources = resources.filter((v) => (v.document_source_path_treeview || []).length === 0).map((r) => {
        return {
            internal_element_id: convertUUIDToNumber(r.document_id || ''),
            document_id: r.document_id,
            element_type: 'file',
            element_name: r.document_name,
            element_path: r.document_source_path,
            parent_element_internal_ids: [],
            children_element_internal_ids: [],
            is_included: false,
            child_is_included: false,
            is_excluded: false,
            child_is_excluded: false
        } as FileStructure
    })

    const combinedElements = [...elements, ...mappedResources]

    const sortedElements = combinedElements.sort((a: FileStructure, b: FileStructure) => {
        return sortFileStructures(a, b, resources, combinedElements)
    })

    return sortedElements
}