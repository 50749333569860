import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { DESIA_EVENT, ResponseDocument, WebSocketResponseWrapper } from "../types/types";
import { WEB_SERVER_ENDPOINT } from "../constants";
import { QueryStatus, WebSocketRequest } from "../types/types";
import { getTimestamp } from "../utils/utils";
import { WebSocketContext } from "./WebSocketContext";

interface Props {
    children: ReactNode;
};

export interface IDocumentStore {
    timestamp: Date;
    data: ResponseDocument[] | null;
    status: QueryStatus;
    fileUpload: File[];
};

export interface IDocumentStoreWithHandlers {
    store: IDocumentStore;
    handlers: {
        listDocuments: () => void;
        uploadDocument: (file: File) => Promise<void>;
    },
};
const initialStoreState: IDocumentStoreWithHandlers = {
    store: {
        timestamp: new Date(),
        data: null,
        status: QueryStatus.INITIALISED,
        fileUpload: []
    },
    handlers: {
        listDocuments: () => { },
        uploadDocument: async () => { },
    },
};

// todo: migrate state management to redux slice
export const DocumentStoreContext = createContext<IDocumentStoreWithHandlers>(initialStoreState);

function DocumentStoreProvider({ children }: Props) {
    const [store, setStore] = useState<IDocumentStore>(initialStoreState.store);
    const secureSocket = useContext(WebSocketContext);
    const socket = secureSocket.socket!;

    const handleListDocumentsRes = (response: WebSocketResponseWrapper<ResponseDocument[]>) => {
        setStore((prev) => {
            if (response.error) {
                return {
                    ...prev,
                    timestamp: new Date(),
                    data: [],
                    status: QueryStatus.ERROR_FETCHING
                }
            }
            return {
                ...prev,
                timestamp: new Date(),
                data: (response.data || []).sort((a, b) => (new Date(b.created_at_desia)).getTime() - new Date(a.created_at_desia).getTime()),
                status: QueryStatus.IDLE
            }
        })
    }

    const handleDocumentStatusUpdate = (updatedDocument: any) => {
        try {
            setStore((prev) => {
                let found = false;
                const newData = prev.data!.map((doc) => {
                    if (doc.document_id === updatedDocument.document_id) {
                        found = true;
                        return { ...doc, ...updatedDocument };
                    }
                    return doc;
                });

                // it's a document not present on the existing list, add it
                const updatedData = found ? newData : [...newData, updatedDocument];

                return {
                    ...prev,
                    timestamp: new Date(),
                    data: updatedData.sort((a, b) => (new Date(b.created_at_desia)).getTime() - new Date(a.created_at_desia).getTime()),
                };
            });
        } catch (e) {
            console.error(e);
        }
    };

    // handlers affecting store state
    const listDocuments = () => {
        setStore(prev => {
            return {
                ...prev,
                status: QueryStatus.FETCHING
            }
        });
        const payload: WebSocketRequest = {
            requestId: "document:list",
            timestamp: getTimestamp(),
            params: {}
        }
        socket.emit(DESIA_EVENT.DOCUMENT_LIST, payload);
    }

    const uploadDocument = async (file: File) => {
        try {
            setStore(prev => {
                return {
                    ...prev,
                    status: QueryStatus.UPLOADING,
                    fileUpload: [...prev.fileUpload, file],
                }
            });

            const formData = new FormData();
            formData.append('file', file);

            const res = await fetch(`${WEB_SERVER_ENDPOINT}/api/document/upload`, {
                method: "post",
                body: formData,
                credentials: "include",
            });
            if (res.status !== 200) {
                throw new Error("failed to upload file");
            }

            const data = await res.json();
            setStore(prev => {
                return {
                    ...prev,
                    fileUpload: prev.fileUpload.filter(f => f.name !== file.name)
                }
            });
            if (data?.success === true) {
                const payload: WebSocketRequest = {
                    requestId: "document:list",
                    timestamp: getTimestamp(),
                    params: {}
                }
                socket.emit(DESIA_EVENT.DOCUMENT_LIST, payload);
            }
        } catch (e) {
            setStore(prev => {
                return {
                    ...prev,
                    status: QueryStatus.ERROR_UPLOADING,
                    fileUpload: prev.fileUpload.filter(f => f.name !== file.name)
                }
            })
            // rethrow to allow conditional polling in caller
            throw e;
        }
    }

    useEffect(() => {
        socket.on(DESIA_EVENT.DOCUMENT_LIST, handleListDocumentsRes);
        socket.on(DESIA_EVENT.DOCUMENT_STATUS_UPDATE, handleDocumentStatusUpdate);

        return () => {
            socket.off(DESIA_EVENT.DOCUMENT_LIST, handleListDocumentsRes);
            socket.off(DESIA_EVENT.DOCUMENT_STATUS_UPDATE, handleDocumentStatusUpdate);
        }
    }, []);

    const storeWithHandlers: IDocumentStoreWithHandlers = {
        store,
        handlers: {
            listDocuments,
            uploadDocument,
        },
    }

    return (
        <DocumentStoreContext.Provider value={storeWithHandlers}>
            {children}
        </DocumentStoreContext.Provider>
    )
}

export const sortDocumentsFn = (a: ResponseDocument, b: ResponseDocument) => {
    const aProcessing = a.document_is_ready_to_use;
    const bProcessing = b.document_is_ready_to_use;

    // Prioritize resources that are ready to use
    if (aProcessing !== bProcessing) {
        return bProcessing ? -1 : 1;
    }

    // If both have the same processing status, sort by date (newest first)
    const aDate = new Date(a.created_at_desia || a?.document_source_details?.integration_created_at || a.document_created_at);
    const bDate = new Date(b.created_at_desia || b?.document_source_details?.integration_created_at || b.document_created_at);

    return bDate.getTime() - aDate.getTime();
}

export {
    DocumentStoreProvider
}
