import { Dossier } from "@/types/types"
import { useState } from "react"
import { DossierCreateDialog } from "../Dossier/DossierCreateDialog"
import { TypographyBody, TypographyH4 } from "../ui/Typography"
import { Button } from "../ui/button"
import ArrowRight from "@/assets/ArrowRight"
import { Plus } from "lucide-react"
import { DossierCard } from "../Dossier/DossierCard"
import { useNavigate } from "react-router-dom"
import { HomeActivityCard } from "./HomeActivityCard"
import { useSelector } from "react-redux"
import { RootState } from "@/store/store"

interface HomePageProps {
    dossiers: Dossier[]
}

export const HomePage = ({ dossiers }: HomePageProps) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)

    const navigate = useNavigate()

    return (
        <>
            <div className="flex flex-col gap-[120px] tablet:mx-auto max-w-[1340px] mt-10">
                <div className="flex flex-col gap-6">
                    <TypographyH4 className="">
                        Activities
                    </TypographyH4>

                    <div className="flex flex-col tablet:flex-row gap-4 laptop:gap-6">
                        <HomeActivityCard type='ask' />
                        <HomeActivityCard type='report' />
                    </div>
                </div>

                <div className="flex flex-col gap-6">
                    <TypographyH4>
                        Recent dossiers
                    </TypographyH4>

                    {dossiers.length === 0 && (
                        <div className="flex flex-col gap-4 mx-auto">
                            <TypographyBody className="text-system-body">
                                There are no dossiers yet.
                            </TypographyBody>

                            <Button variant='secondary' className="w-fit mx-auto" onClick={() => setShowCreateDialog(true)}>
                                <div className="flex gap-2">
                                    <Plus className="size-6 shrink-0 stroke-[1.5px] !stroke-link" />

                                    Create dossier
                                </div>
                            </Button>
                        </div>
                    )}

                    {dossiers.length > 0 && (
                        <>
                            <Button className="w-fit" onClick={() => setShowCreateDialog(true)}>
                                <div className="flex gap-2 w-fit">
                                    <Plus className="size-6 shrink-0 stroke-[1.5px]" />

                                    Create dossier
                                </div>
                            </Button>

                            <div className="grid tablet:!hidden grid-cols-1 mobile:grid-cols-2 laptop:grid-cols-3 gap-x-6 gap-y-3">
                                {[...dossiers].slice(0, 4).map((v) => <DossierCard key={v.id} dossier={v} compact={true} />)}
                            </div>

                            <div className="hidden tablet:!grid grid-cols-1 mobile:grid-cols-2 laptop:grid-cols-3 gap-x-6 gap-y-3">
                                {[...dossiers].slice(0, 6).map((v) => <DossierCard key={v.id} dossier={v} compact={true} />)}
                            </div>

                            <Button variant='tertiary' className="ml-auto" onClick={() => navigate('/dossiers')}>
                                <div className="flex gap-2 w-fit">
                                    View all dossiers

                                    <ArrowRight className="size-6 shrink-0" />
                                </div>
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <DossierCreateDialog open={showCreateDialog} setOpen={(v) => setShowCreateDialog(v)} />
        </>
    )
}

export const HomePageContainer = () => {
    const dossiers = useSelector((state: RootState) => state.dossier.dossiers);
    return (
        <HomePage dossiers={dossiers.data || []} />
    )
}