import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import { Dispatch, lazy, SetStateAction, Suspense, useState } from "react";

import { FileStructure, ResponseDocument } from "@/types/types";

import { Skeleton } from "../ui/skeleton";
import { ErrorMessage } from "../ErrorMessage";
import { DeleteResourceDialog } from "../Resources/DeleteResourceDialog";
import { NotAvailableDialog } from "../Resources/NotAvailableDialog";
import { toggleElement } from "@/utils/utils";

const FileRow = lazy(() => import('./FileRow'));

export interface FileTableProps {
    elements: FileStructure[]
    shownElements: FileStructure[]
    resources?: ResponseDocument[]
    setElements?: Dispatch<SetStateAction<FileStructure[]>>
    showHeader?: boolean
    showCheckbox?: boolean
    loading?: boolean
    error?: boolean
    type: 'integration' | 'ask' | 'library' | 'dossier',
    onRemove?: ({ id, title }: { id: string, title: string }) => void
}

export const FileTable = ({ elements, shownElements, resources, setElements, showHeader, showCheckbox, loading, error, type, onRemove }: FileTableProps) => {
    const [openedFolders, setOpenedFolders] = useState<number[]>([])
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [fileToDelete, setFileToDelete] = useState<ResponseDocument | null>(null)
    const [showAnalyseDialog, setShowAnalyseDialog] = useState(false)

    const rootElements = elements?.filter((v) => v.parent_element_internal_ids.length === 0) || []

    const toggleOpenFolder = (id: number) => {
        if (openedFolders.includes(id)) {
            setOpenedFolders(openedFolders.filter((v) => v !== id))
        } else {
            setOpenedFolders([...openedFolders, id])
        }
    }

    return (
        <>
            {!loading && !error && (
                <Table>
                    {showHeader && (
                        <TableHeader>
                            <TableRow>
                                {type === 'integration' && (
                                    <>
                                        {showCheckbox && (
                                            <TableHead className="font-body-strong text-system-primary w-[44px]"></TableHead>
                                        )}
                                        <TableHead className="font-body-strong text-system-primary !max-w-[640px] !overflow-hidden">File name</TableHead>
                                        <TableHead className="font-body-strong text-system-primary whitespace-nowrap">Uploaded on</TableHead>
                                        <TableHead className="font-body-strong text-system-primary">File size</TableHead>
                                    </>
                                )}

                                {type !== 'integration' && (
                                    <>
                                        {type === 'ask' && (
                                            <TableHead className="font-body-strong text-system-primary"></TableHead>
                                        )}
                                        <TableHead className="font-body-strong text-system-primary w-[calc(100%-154px-120px-161px)] shrink">File name</TableHead>

                                        <TableHead className="font-body-strong text-system-primary whitespace-nowrap shrink-0">Directory</TableHead>
                                        {type !== 'ask' && <TableHead className="font-body-strong text-system-primary w-[120px] whitespace-nowrap shrink-0">File size</TableHead>}
                                        <TableHead className="font-body-strong text-system-primary whitespace-nowrap shrink-0">Updated on</TableHead>
                                        {type !== 'ask' && <TableHead className="font-body-strong text-system-primary w-[56px] shrink-0"></TableHead>}
                                    </>
                                )}
                            </TableRow>
                        </TableHeader>
                    )}

                    <TableBody>
                        {rootElements.map((d) => (
                            <Suspense>
                                <FileRow
                                    key={d.document_id || d.internal_element_id}
                                    elements={elements}
                                    shownElements={shownElements}
                                    resources={resources || []}
                                    file={d}
                                    level={0}
                                    openedFolders={openedFolders}
                                    toggleOpenFolder={toggleOpenFolder}
                                    toggleElement={(v) => toggleElement(v, elements, setElements)}
                                    setFileToDelete={(v) => {
                                        setFileToDelete(v)
                                        setShowDeleteDialog(true)
                                    }}
                                    type={type}
                                    showCheckbox={showCheckbox || false}
                                    setShowAnalyseDialog={(v) => setShowAnalyseDialog(v)}
                                    onRemove={onRemove}
                                />
                            </Suspense>
                        ))}
                    </TableBody>
                </Table>
            )}

            {loading && (
                <div>
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                </div>
            )}

            {!loading && error && (
                <ErrorMessage message="We failed to list resources, please try again shortly" />
            )}

            <DeleteResourceDialog resource={fileToDelete} open={showDeleteDialog} setOpen={(v) => setShowDeleteDialog(v)} />
            <NotAvailableDialog open={showAnalyseDialog} setOpen={(v) => setShowAnalyseDialog(v)} variant='doc-analysis' />
        </>

    )
}