import { useEffect, useState } from "react"
import { Button } from "../ui/button"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog"
import { Input } from "../ui/input"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { createDossier, fetchDossiers } from "./dossierSlice"
import { useNavigate } from "react-router-dom"
import { ASYNC_STATUS, Dossier } from "@/types/types"
import { ErrorMessage } from "../ErrorMessage"

export const DossierCreateDialog = ({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) => {
    const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
    const createDossierStatus = useSelector((state: RootState) => state.dossier.createDossier.status)
    const [name, setName] = useState('')

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const handleCreate = async () => {
        if (!name) return

        const icons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
        const last9Dossiers = dossiers.data?.slice(0, 9).map((v) => v.icon) || []

        const action = await dispatch(createDossier({
            subject: name,
            description: null,
            icon: icons.find((v) => !last9Dossiers.includes(v)) || '1'
        }))

        if (action.meta.requestStatus === 'fulfilled') {
            const payload = action.payload as Dossier

            setOpen(false)

            navigate(`/dossier/${payload.id}`)

            dispatch(fetchDossiers())
        }
    }

    useEffect(() => {
        if (open) {
            setName('')
        }
    }, [open])

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="w-fit space-y-8">
                <DialogHeader>
                    <DialogTitle>Create dossier</DialogTitle>
                </DialogHeader>

                <Input
                    label="Name"
                    className="w-full mobile:w-[320px]"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                {createDossierStatus === ASYNC_STATUS.error && (
                    <ErrorMessage message="Failed to create dossier. Please try again" />
                )}

                <DialogFooter>
                    <DialogClose>
                        <Button variant="secondary">Cancel</Button>
                    </DialogClose>
                    <Button onClick={() => handleCreate()} >
                        Confirm and create
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}