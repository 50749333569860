import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { followUpAsk, newAsk, setToolComponent } from "../docGenSlice";
import { SourceSelector } from "@/components/Assistant/SourceSelector";
import { ChevronLeft, ChevronRight, MessageCircleQuestionIcon, X } from "lucide-react";
import { RootState } from "@/store/store";
import { getMessageId, getTimestamp } from "@/utils/utils";
import shortid from "shortid";
import { SystemChatMessage, UserChatMessage } from "@/components/Chat";
import { Conversation, DossierDetail, SystemMessage } from "@/types/types";
import { Button } from "@/components/ui/button";
import { TypographyMuted } from "@/components/ui/Typography";
import { OutputData } from "@editorjs/editorjs";
import { convertReportToString } from "@/utils/docgen";
import { getAskTools } from "@/utils/ask";
import { UserContext } from "@/contexts/UserContext";
import Divider from "@/components/ui/divider";

function getConversationPair({ conversation, page }: {
    conversation: Conversation,
    page: number | null,
}) {
    try {
        if (!conversation.length) {
            return [];
        }
        if (page === null) {
            return [conversation[conversation.length - 2], conversation[conversation.length - 1]];
        }
        return [conversation[(2 * page)], conversation[(2 * page) + 1]];
    } catch (e) {
        console.error(e);
        return conversation;
    }
}

function getNextPage({ conversation, page, action }: {
    conversation: Conversation,
    page: number | null,
    action: 'next' | 'previous',
}) {
    try {
        if (!conversation.length) {
            return page;
        }
        const maxPage = conversation.length / 2;

        if (action === 'next') {
            // last page
            if (page === null) {
                return page;
            }
            if (page === maxPage - 1) {
                return page;
            }
            return page + 1;
        }

        if (action === 'previous') {
            // last page
            if (page === null) {
                return (maxPage) - 2;
            }
            // first page
            if (page === 0) {
                return page;
            }
            return page - 1;
        }

        return page;
    } catch (e) {
        console.error(e);
        return page;
    }
}

function PageToggle({ currentPage, maxPage, handlePageToggle, handleClose }: {
    currentPage: number, maxPage: number, handlePageToggle: (action: 'next' | 'previous') => void
    handleClose: () => void
}) {
    if (maxPage <= 1) {
        return (
            <div className="cursor-pointer ml-auto" onClick={() => handleClose()}>
                <X className="w-6 h-6 shrink-0 stroke-[1.5px]" />
            </div>
        )
    }

    return (
        <div className="flex items-center shrink-0 ml-auto">
            <div className="flex gap-1 items-center">
                {currentPage === 1 ?
                    <div className="w-6 h-6">

                    </div>
                    :
                    <div onClick={() => { handlePageToggle("previous") }} className="w-fit cursor-pointer">
                        <ChevronLeft className="w-6 h-6 stroke-[1.5px]" />
                    </div>

                }

                <TypographyMuted>
                    {currentPage} of {maxPage}
                </TypographyMuted>

                {(currentPage === null || currentPage === maxPage) ?
                    <div className="w-6 h-6">

                    </div>
                    :
                    <div onClick={() => { handlePageToggle("next") }} className="w-fit cursor-pointer">
                        <ChevronRight className="w-6 h-6 stroke-[1.5px]" />
                    </div>

                }
            </div>

            <div className="cursor-pointer ml-auto" onClick={() => handleClose()}>
                <X className="w-6 h-6 shrink-0 stroke-[1.5px]" />
            </div>
        </div>
    )
}

export function AskPanelTool({ reportId, data, dossierDetail, addToDocument }: { reportId: string, data: OutputData, dossierDetail?: DossierDetail, addToDocument: (systemMessage: SystemMessage) => void }) {
    const { settings } = useContext(UserContext)

    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const [page, setPage] = useState<number | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const conversationByReportId = useSelector((state: RootState) => state.docGen.ask.byReportId);
    const conversation = conversationByReportId[reportId]?.response?.conversation || [];
    const isFollowUp = conversation.length > 0;

    const messagePair = getConversationPair({
        conversation: conversation,
        page,
    })
    const currentSystemMessage = messagePair.find(m => m.role === 'system') as SystemMessage;

    const isSourceSelected = settings.assistant.sources.length > 0

    function handlePageToggle(action: 'next' | 'previous') {
        const nextPage = getNextPage({
            conversation: conversation || [],
            page,
            action,
        });
        setPage(nextPage);
    }

    function handleClose() {
        setMessage('')
        dispatch(setToolComponent(null));
    }

    const handleAsk = (message: string) => {
        const requestId = shortid();
        const reportContent = convertReportToString(data);

        //@ts-expect-error
        const fileIds = settings.assistant.sources.find((v) => v.id === 'internal-search')?.include?.map((v) => v.id) || []

        const baseParams = {
            message,
            context: reportContent,
            reportId,
            focusedAnalysis: fileIds.length > 0,
            fileIDs: fileIds,
        }
        if (isFollowUp) {
            dispatch(followUpAsk({
                requestId,
                timestamp: getTimestamp(),
                params: {
                    conversationId: conversationByReportId[reportId].response.conversationId,
                    ...baseParams,
                    ...getAskTools(settings)
                }
            }))
        } else {
            dispatch(newAsk({
                requestId,
                timestamp: getTimestamp(),
                params: {
                    ...baseParams,
                    ...getAskTools(settings)
                }
            }));
        }
    }

    const maxPage = conversation.length / 2;
    const currentPage = page === null ? maxPage : (page + 1);

    useEffect(() => {
        if (page === null) return;
        if (page < maxPage) {
            setPage(null);
        }
    }, [conversation.length]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [message])

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "24px";
        }
    }, [])

    return (
        <div className={"w-full"} onKeyDown={(evt) => {
            if (evt.code === 'Escape') {

                handleClose();
            }
        }}>
            <div>
                <div className="flex flex-col bg-system-surface border border-system-border-regular rounded-lg max-h-[calc(100vh-80px-32px-48px-128px)] px-3 py-3.5 pl-4 w-full">
                    <div className={`overflow-y-scroll`}>
                        {messagePair
                            .map((chatMessage, idx) => {
                                try {
                                    if (chatMessage.role === 'user') {
                                        return <div className="flex gap-2 items-start w-full">
                                            <UserChatMessage key={`user__${idx}`} message={chatMessage} compact={true} />

                                            <div className="mt-[2px] shrink-0 ml-auto">
                                                <PageToggle
                                                    currentPage={currentPage}
                                                    maxPage={maxPage}
                                                    handlePageToggle={(action) => { handlePageToggle(action) }}
                                                    handleClose={handleClose}
                                                />
                                            </div>
                                        </div>
                                    }
                                    if (chatMessage.role == 'system') {
                                        const messageId = getMessageId({
                                            conversationId: chatMessage.conversationId || "",
                                            created_at: chatMessage.timestamp,
                                            requestId: chatMessage.requestId,
                                        });
                                        return (
                                            <SystemChatMessage
                                                key={`system_${idx}`}
                                                messageId={messageId}
                                                message={chatMessage}
                                                selectedCitations={[]}
                                                onSelectCitation={() => { }}
                                                onShowAllCitations={() => { }}
                                                hoveredCitations={[]}
                                                onHoverCitation={() => {
                                                }}
                                                onHoverOffCitation={() => {
                                                }}
                                                hoveredDocument={""}
                                                onHoverDocument={() => { }}
                                                compact={true}
                                                onFollowUpQuestionClick={(v) => handleAsk(v)}
                                                showFollowUpQuestions={true}
                                            />
                                        )
                                    }
                                    return <></>
                                } catch (e) {
                                    console.error(e)
                                    return <></>;
                                }
                            })}
                    </div>

                    {messagePair.length > 0 && (
                        <Divider />
                    )}

                    <div className={`flex gap-4 items-start ${messagePair.length > 0 ? 'mt-4' : ''}`}>
                        <MessageCircleQuestionIcon className="w-6 h-6 text-link shrink-0 stroke-[1.5px] mt-[2px]" />
                        <textarea className="max-h-[320px] h-fit w-full font-body placeholder:!text-system-placeholder !resize-none focus:outline-none bg-transparent mt-[2px]"
                            ref={textareaRef}
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && e.shiftKey !== true) {
                                    e.preventDefault();

                                    if (!isSourceSelected) return
                                    handleAsk(message)
                                    setMessage("");
                                }
                            }}
                            placeholder="What do you need to know?"
                            autoFocus
                        />
                        <SourceSelector dossierDetail={dossierDetail} />
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button variant={"secondary"} className={`mt-2 ${currentSystemMessage?.data?.isFinished !== true ? 'opacity-0' : 'opacity-1'}`} size={"sm"}
                        onClick={() => {
                            if (currentSystemMessage) {
                                addToDocument(currentSystemMessage);
                                handleClose();
                            }
                        }}
                        disabled={currentSystemMessage?.data?.isFinished !== true}
                    >Add to report</Button>
                </div>
            </div>
        </div>
    )
}