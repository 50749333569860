import { MAX_ICONS_INLINE_SOURCES } from "@/constants";
import { ChatMessage, DESIA_EVENT, RequestAssistantAsk, ResponseChatStream, SourceDocument, UserMessage, WebSocketRequestWrapper } from "@/types/types";
import { getIconSrc, getTimestamp, plural } from "@/utils/utils";
import { Button } from "../ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Sources } from "./Sources";
import { Skeleton } from "../ui/skeleton";
import FileText from "../../assets/FileText"
import Stack from "@/assets/Stack";
import { getGlobalUniqueDocuments } from "@/utils/components";
import { CustomTooltip } from "../CustomTooltip";
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import { RotateCcw } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { ASSISTANT_ACTIONS, AssistantStoreContext, getLiveConversationById } from "@/contexts/AssistantContext";
import shortid from "shortid";
import { UserContext } from "@/contexts/UserContext";
import { getAskTools } from "@/utils/ask";
import { useSocketQuery } from "@/hooks/useSocketQuery";

interface Props {
    documents: SourceDocument[];
    compact?: boolean;
    maxIcons?: number;
    loading?: boolean;
    className?: string;
    message?: ChatMessage
}

interface WebIconsProps {
    documents: SourceDocument[];
    maxIcons?: number;
}

interface ViewSourcesProps {
    documents: SourceDocument[];
    compact?: boolean;
    message?: ChatMessage
    loading?: boolean
}

const iconStyle = "w-4 h-4 border rounded-[2px]";


function WebIcons({ documents, maxIcons }: WebIconsProps) {
    return (
        <div className="flex gap-1">
            {documents
                .filter(d => d.document_id.includes("web"))
                .reduce((acc, cur) => {
                    const { hostname } = new URL(cur.url);
                    if (acc.includes(hostname)) {
                        return acc;
                    }
                    return [...acc, hostname];
                }, [] as string[])
                .filter((_, idx) => idx < (maxIcons ? maxIcons : MAX_ICONS_INLINE_SOURCES))
                .map(hostname => {
                    const src = getIconSrc(hostname);
                    return (
                        <div key={`favicon-${hostname}`}>
                            <img src={src} className={iconStyle} data-tooltip-id={`web-icon-tooltip-${hostname}`} />

                            <CustomTooltip
                                id={`web-icon-tooltip-${hostname}`}
                                className="!py-1 !px-3 !rounded-sm"
                                largeArrow={false}
                            >
                                <TypographyLabel>
                                    {hostname}
                                </TypographyLabel>
                            </CustomTooltip>
                        </div>
                    )
                })}
        </div>
    )
}

function ViewSources({ documents, compact, message, loading }: ViewSourcesProps) {
    const [open, setOpen] = useState(false)

    const { store, dispatch } = useContext(AssistantStoreContext);
    const { settings } = useContext(UserContext);

    const filteredDocuments = getGlobalUniqueDocuments(documents, false)

    const [selectedDocuments, setSelectedDocuments] = useState(filteredDocuments)

    const requestId = message?.requestId || `new_ask_${shortid()}`

    const conversation = getLiveConversationById({
        store,
        conversationId: message?.conversationId || "",
        requestId,
    })

    const { executeQuery, state: queryState } = useSocketQuery({
        event: DESIA_EVENT.CHAT_ASK,
        request: {
            requestId: requestId,
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: true,
            callback: (response) => {
                const res = response.data as ResponseChatStream; // fixme
                dispatch({
                    action: ASSISTANT_ACTIONS.STREAMING_RESPONSE,
                    data: {
                        ...res,
                        requestId: response.requestId,
                        timestamp: response.timestamp,
                    }
                })
            }
        },
    })

    const isLoading = loading || queryState.loading

    function handleSubmit() {
        const systemMessageIndex = conversation.findIndex((v) => v === message)
        const userMessage = conversation[systemMessageIndex - 1] as UserMessage
        const timestamp = getTimestamp()
        const query = userMessage.query || ''

        dispatch({
            action: ASSISTANT_ACTIONS.FOLLOW_UP_ASK,
            data: {
                conversationId: message?.conversationId || '',
                requestId: `${requestId}_followup_${conversation.length}`,
                question: query,
                timestamp,
                mode: 'simple',
            }
        })

        const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
            requestId,
            timestamp,
            params: {
                message: query,
                mode: 'simple',
                regeneration: true,
                documents: selectedDocuments,
                conversationId: message?.conversationId,
                ...getAskTools(settings),
            }
        }
        executeQuery({
            event: DESIA_EVENT.CHAT_ASK,
            request
        });

        setOpen(false)
    }

    useEffect(() => {
        setSelectedDocuments(filteredDocuments)
    }, [loading])

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogTrigger asChild>
                <Button variant={"tertiary"} className="flex gap-2">
                    {compact ? (
                        <Stack className="h-6 w-6 !stroke-link" />
                    ) : (
                        <>
                            <Stack className="h-6 w-6" />

                            <span className="font-body-strong text-system-primary">
                                View sources
                            </span>
                        </>
                    )}
                </Button>

            </DialogTrigger>
            <DialogContent className="!w-[calc(100vw-48px)] tablet:!max-w-[710px] sm:!max-w-[710px] py-8 flex flex-col gap-10 top-6 mobile:top-20 translate-y-0">
                <DialogHeader>
                    <DialogTitle>Sources</DialogTitle>
                    <DialogDescription className="text-system-body font-body">
                        View or select which sources to look through for the response
                    </DialogDescription>
                </DialogHeader>
                <div className="grid grid-cols-1">
                    <Sources
                        documents={filteredDocuments}
                        showTabs={true}
                        selectable={true}
                        selectedDocuments={selectedDocuments}
                        setSelectedDocuments={setSelectedDocuments}
                    />
                </div>
                {selectedDocuments.length !== filteredDocuments.length && (
                    <DialogFooter>
                        <Button variant='secondary' className="mr-auto" disabled={isLoading}>
                            <div className="flex gap-2">
                                <RotateCcw className="size-6 shrink-0 stroke-[1.5px]" />

                                <TypographyBody className="text-system-primary flex mobile:!hidden" onClick={() => handleSubmit()}>
                                    Retry response
                                </TypographyBody>

                                <TypographyBody className="text-system-primary hidden mobile:!flex" onClick={() => handleSubmit()}>
                                    Retry response with selected sources
                                </TypographyBody>
                            </div>
                        </Button>
                    </DialogFooter>
                )}
            </DialogContent>
        </Dialog>
    )
}

export function PreviewSources({ documents, compact, maxIcons, loading, message, className }: Props) {
    if (loading && compact) {
        return (
            <Skeleton className="w-full h-10 my-2" />
        )
    }

    if (documents.length === 0) return (
        <div className="w-full m-h-40px my-2">

        </div>
    )

    const nonChartDocuments = documents.filter((v) => !v.document_id.includes('chart'))
    const webpages = nonChartDocuments.filter(d => d.document_id.includes("web"));
    const internalDocuments = nonChartDocuments.filter(d => !d.document_id.includes("web"));
    return (
        <div className={`$w-full ${className || ""}`}>
            <div className="flex flex-wrap gap-4 justify-between items-center">
                <div className="flex gap-6 items-center">
                    <div className="flex gap-2 items-center">
                        {webpages.length > 0 && (
                            <>
                                <div className="flex gap-1">
                                    <WebIcons documents={nonChartDocuments} maxIcons={maxIcons} />
                                </div>
                                <span className="font-label text-system-body flex md:!hidden !mb-0">{webpages.length}</span>
                                <span className="font-label text-system-body hidden md:!flex">{webpages.length} {plural("webpage", webpages.length)}</span>
                            </>
                        )}
                    </div>
                    <div className="flex gap-2 items-center">
                        {internalDocuments.length > 0 && (
                            <>
                                <FileText className="h-4 w-4 p-0.5 border border-system-border-light rounded-[2px]" />
                                <span className="font-label text-system-body flex md:!hidden !mb-0">{internalDocuments.length}</span>
                                <span className="font-label text-system-body hidden md:!flex">{internalDocuments.length} {plural("file", internalDocuments.length)}</span>
                            </>
                        )}
                    </div>
                </div>
                <ViewSources documents={nonChartDocuments} compact={compact} message={message} loading={loading} />
            </div>
        </div>
    )
}
