import { DEBUG_MODE, WEB_SERVER_ENDPOINT } from "@/constants";
import { WebSocketRequestWrapper } from "@/types/types";
import { io, Socket } from "socket.io-client";
import { handleLogin } from "./utils";
import wait from "wait";

type SocketStatus = 'idle' | 'connecting' | 'error' | 'connected' | 'reconnecting' | 'disconnected';

class SocketConnection {
    public socket: Socket | null;
    public status: SocketStatus;
    public createdAt: string;
    public reconnectAttemptsRemaining: number;
    public maxReconnectAttempts: number;

    public statusChangeSubscribers: ((status: SocketStatus) => void)[];
    public reconnectAttemptsRemainingSubscribers: ((attempts: number) => void)[];

    constructor() {
        this.socket = null;
        this.status = 'idle';
        this.createdAt = new Date().toISOString();
        this.maxReconnectAttempts = 10;
        this.reconnectAttemptsRemaining = this.maxReconnectAttempts;

        this.statusChangeSubscribers = [];
        this.reconnectAttemptsRemainingSubscribers = []
    }

    emit(event: string, message: WebSocketRequestWrapper<any>) {
        if (this.socket) {
            this.socket.emit(event, message)
        }
    }

    on(eventName: string, callback: (message: string | Object) => void) {
        if (this.socket) {
            this.socket.on(eventName, callback)
        }
    }

    async _connect() {
        try {
            if (this.status !== 'reconnecting') {
                this.status = 'connecting';
                this.publishStatusChange();
            }

            const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/user/ws-ticket`, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            if (response.status === 403) {
                return handleLogin();
            }
            const { ticket } = await response.json();
            this._securelyConnect(ticket);
            return true;
        } catch (e) {
            console.error(e);
            if (this.status === 'connecting') {
                this.status = 'error';
            }
            this.publishStatusChange();
            return false;
        }
    }

    async _reconnect() {
        console.info(`[desia-web-app]: reconnecting`);
        this.status = 'reconnecting';
        this.publishStatusChange();

        while (['connecting', 'reconnecting'].includes(this.status) && this.reconnectAttemptsRemaining > 0) {
            console.info(`[desia-web-app]: attempt ${this.reconnectAttemptsRemaining}`);
            await wait(5_000);
            const connected = await this._connect();
            if (connected) {
                console.info(`[desia-web-app]: reconnection successful`);
                this.reconnectAttemptsRemaining = this.maxReconnectAttempts;
                this.publishReconnectAttemptsRemaining();
                return true;
            }
            this.reconnectAttemptsRemaining = this.reconnectAttemptsRemaining - 1;
            this.publishReconnectAttemptsRemaining();
        }

        this.status = 'disconnected';
        this.publishStatusChange();

        this.reconnectAttemptsRemaining = this.maxReconnectAttempts;
        this.publishReconnectAttemptsRemaining();
        return false;
    }

    _securelyConnect (ticket: string) {
        this.socket = io(WEB_SERVER_ENDPOINT, {
            path: '/ws',
            auth: {
                ticket
            },
            query: {
              main: true
            },
            reconnection: false,
            forceNew: true,
        });

        this.socket.on('connect', () => {
            this.status = 'connected';
            this.publishStatusChange();
        });

        this.socket.on("disconnect", (...args) => {
            console.warn("websocket disconnected - attempting to reconnect.", args);
            this._reconnect();
        })

        if (DEBUG_MODE) {
            console.info('[socket connection] desia running in debug mode');
            this.socket.onAnyOutgoing((event, response) => {
                console.info(`
event: ${event}
request:
${JSON.stringify(response, null, 4)}`);
            });
            this.socket.onAny((event, response) => {
                console.info(`
event: ${event}
response:
${JSON.stringify(response, null, 4)}`);
            });
        }
    }

    subscribeToStatusChange(callback: (status: SocketStatus) => void) {
        this.statusChangeSubscribers.push(callback);
    }
    publishStatusChange() {
        this.statusChangeSubscribers.forEach((callback) => {
            callback(this.status);
        });
    }

    subscribeToReconnectAttemptsRemaining(callback: (attempts: number) => void) {
        this.reconnectAttemptsRemainingSubscribers.push(callback);
    }
    publishReconnectAttemptsRemaining() {
        this.reconnectAttemptsRemainingSubscribers.forEach((callback) => {
            callback(this.reconnectAttemptsRemaining);
        });
    }

    connect() {
        this._connect();
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

const instance = new SocketConnection();

export { SocketConnection, instance as socketConnection, type SocketStatus };
