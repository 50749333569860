import { SourceDocumentId } from "../contexts/AssistantContext";
import { Citation, SourceDocument } from "../types/types";
import { normaliseDocumentId } from "./utils";

// https://www.30secondsofcode.org/js/s/replace-last-occurrence/
const replaceLast = (str: string, pattern: string | RegExp, replacement: string): string => {
    const spaceCharacter = "‎";
    const match =
        typeof pattern === 'string'
            ? pattern
            : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
    if (!match) return str;
    const last = str.lastIndexOf(match);
    return last !== -1
        ? `${str.slice(0, last)}${spaceCharacter}${replacement}${spaceCharacter}${str.slice(last + match.length)}`
        : str;
};

const formatCitationText = (text: string): string => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const hasBoldMarkdown = boldRegex.test(text);

    const replacedText = (hasBoldMarkdown ? text : text.replaceAll('**', ''));
    return replacedText
}

export function embedCitations(props: { text: string, citations: Citation[], highlightCitation: SourceDocumentId }): string {
    const { text, citations, highlightCitation } = props;
    try {
        const input = text;
        let pointer = text.length;
        let orderedCitations = citations.sort((a, b) => Number(a.start) - Number(b.start));
        if (!text) return text;
        if (!citations || citations.length === 0) return text;

        let mutableResult = input;
        for (let i = orderedCitations.length; i > 0; i--) {

            const citation = orderedCitations[i - 1];
            const pre = citation.text;
            const highlight = citation.document_ids.map(id => normaliseDocumentId(id).id).includes(normaliseDocumentId(highlightCitation).id);
            const post = `<Cite highlight={${highlight}} documentIds={[${citation.document_ids.map(x => `"${x}"`).join(",")}]}>${pre}</Cite>`;

            const left = mutableResult.substring(0, pointer);
            const right = mutableResult.substring(pointer, mutableResult.length);
            mutableResult = `${replaceLast(left, pre, post)}${right}`;
            pointer = citation.start;
        }
        return mutableResult;
    } catch (e) {
        console.error(e);
        return text;
    }
}

// todo: replace embedCitations with embedCitationsV2 once new design fully integrated
export function embedCitationsV2(props: { text: string, citations: Citation[], documents: SourceDocument[], highlightCitation: SourceDocumentId, isComplete: boolean }): string {
    const { text, citations, documents, highlightCitation } = props;
    try {
        const citationsCopy = [...citations];
        const input = text;
        let pointer = text.length;
        let orderedCitations = citationsCopy.sort((a, b) => Number(a.start) - Number(b.start));
        const prefix = `<span>`;
        const postfix = `</span>`;
        // show dot cursor iff answer is still streaming
        // n.b. citations are loaded after answer streamed
        const cursor = `<Cursor show={${props.isComplete === false && !citations.length}} />`;
        if (!text) return `${prefix}${cursor}${postfix}`;
        if (!citations || citations.length === 0) return `${prefix}${text}${cursor}${postfix}`;

        let mutableResult = input;
        for (let i = orderedCitations.length; i > 0; i--) {

            const citation = orderedCitations[i - 1];

            // if citation refers to an image then render the base64 image
            const doc = documents.find(d => citation.document_ids.map((id) => normaliseDocumentId(id).id).includes(normaliseDocumentId(d.document_id).id));
            if (!doc) continue;
            if (doc.output_file?.b64_data) {
                const pre = citation.text;
                const left = mutableResult.substring(0, pointer);
                // regex to replace all text between parentheses (filename) with base64 image
                let post = "";
                if (pre.includes("![")) {
                    post = pre.replace(/\(([^)]+)\)/g, `(data:image/png;base64,${doc.output_file!.b64_data})`);
                } else {
                    // new format does not contain markdown image prefix so add it here
                    // speak to Adi
                    post = pre.replace(/\(([^)]+)\)/g, `![chart](data:image/png;base64,${doc.output_file!.b64_data})`);
                }
                const right = mutableResult.substring(pointer, mutableResult.length);
                mutableResult = `${replaceLast(left, pre, post)}${right}`;
                pointer = citation.start;
                continue;
            }
            const pre = formatCitationText(citation.text)
            const highlight = citation.document_ids.map(id => normaliseDocumentId(id).id).includes(normaliseDocumentId(highlightCitation).id);
            const post = `<Cite highlight={${highlight}} documentIds={[${citation.document_ids.map(x => `"${x}"`).join(",")}]}>${pre}</Cite>`;
            const left = mutableResult.substring(0, pointer);
            const right = mutableResult.substring(pointer, mutableResult.length);
            mutableResult = `${replaceLast(left, pre, post)}${right}`;
            pointer = citation.start;
        }

        const result = `${prefix}${mutableResult}${cursor}${postfix}`
        // ``` markdown isn't rendered correctly so we have to replace it here
        const formattedResult = result.replace(/```([^`]*)```/g, '<pre>$1</pre>');
        return formattedResult
    } catch (e) {
        console.error(e);
        return text;
    }
}

export const embedCharts = ({ text }: { text: string }) => {
    const regex = /!\[(.*?)\]/g
    return text.replaceAll(regex, `<Chart id={$1} />`)
}