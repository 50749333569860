import { API } from "@editorjs/editorjs";
import ReactDOM from "react-dom/client";

export class ParagraphTool {
    private container: HTMLDivElement;
    private root: ReactDOM.Root | null = null;
    private text: string | null;
    private api: API;
    private onSlash: () => void

    static get toolbox() {
        return {
            title: 'Text',
            icon: `<div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-type"><polyline points="4 7 4 4 20 4 20 7"/><line x1="9" x2="15" y1="20" y2="20"/><line x1="12" x2="12" y1="4" y2="20"/></svg></div>`
        };
    }

    static get pasteConfig() {
        return {
            tags: []
        }
    }

    static get enableLineBreaks() {
        return true
    }

    constructor({ data, api, config }: { data: any, api: API, config: any }) {
        this.text = data.text || '';
        this.container = document.createElement('div');
        this.api = api
        this.onSlash = config.onSlash
    }

    keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "/" && !this.text) {
            e.preventDefault()
            this.api.toolbar.toggleToolbox(false)
            console.log('debug prevent')
        }

        if (e.key === 'Enter') {
            e.preventDefault()

            this.api.blocks.insert('NewParagraph', {}, undefined, this.api.blocks.getCurrentBlockIndex() + 1, true);
        }

        if (e.key === "Backspace" && !this.text) {
            e.preventDefault()
            const index = this.api.blocks.getCurrentBlockIndex()
            this.api.blocks.delete(index)
            this.api.caret.setToBlock(index)
        }
    }

    inputHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        this.text = e.currentTarget.innerText
    };

    checkText = () => {
        if (!this.text) {
            this.onSlash()
        }
        return this.text
    }

    render() {
        this.root = ReactDOM.createRoot(this.container)
        this.renderContainer()

        setTimeout(() => {
            this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex())
        }, 1)

        return this.container;
    }

    renderContainer() {
        this.root?.render(
            <div className="relative group py-1">
                {/* The placeholder div prevents the slash from getting added into the text for some reason */}
                {!this.text && (
                    <p className="text-transparent absolute hidden group-focus-within:block">Placeholder div</p>
                )}
                <div className="focus:outline-none relative placeholder:text-system-placeholder bg-transparent w-full h-fit" data-placeholder="Press “/” key to Ask Desia" contentEditable suppressContentEditableWarning dangerouslySetInnerHTML={{ __html: this.text || '' }} onKeyDown={this.keyDownHandler} onInput={this.inputHandler}>

                </div>
            </div>
        )
    }

    save() {
        const data = {
            text: this.text
        }
        return data;
    }
}
