import { NotebookPen } from "lucide-react";
import { Button, secondaryStyle } from "../ui/button";
import { TypographyBody, TypographyH3, TypographyLabel } from "../ui/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { requestCreateReport, requestReports, resetCreateReportData } from "../DocGen/docGenSlice";
import { getTimestamp } from "@/utils/utils";
import { ResponseDocGenReport } from "@/types/types";
import { ErrorMessage } from "../ErrorMessage";

import { ReportList } from "./ReportList";
import { Input } from "../ui/input";
import { useNavigate } from "react-router-dom";

import DocGenHomeImage from '../../assets/img-home-doc-gen.svg'
import { NotAvailableDialog } from "../Resources/NotAvailableDialog";

export interface MockReport {
    title: string
    description: string
}

// todo: implement pagination
export function ReportsPage({ reports, error, loading, createReportLoading, createReportError, handleCreateReport }: {
    reports: ResponseDocGenReport[],
    loading: boolean,
    error: any,
    createReportLoading?: boolean,
    createReportError?: string | null,
    handleCreateReport: () => void,
}) {
    const [searchText, setSearchText] = useState('')
    const [showNotAvailableDialog, setShowNotAvailableDialog] = useState(false)

    const filteredReports = reports.filter((v) => {
        if (!searchText) { return true }
        const splitText = searchText.toLowerCase().split(' ')
        return splitText.some((text) => v.title.toLowerCase().includes(text)) && v.content !== ''
    })

    return (
        <div className="flex flex-col gap-10 mt-10 sm:mt-0 tablet:max-w-[956px] laptop:max-w-[1440px] mx-auto">
            <div onClick={() => setShowNotAvailableDialog(true)} className={`flex gap-6 items-center rounded-[16px] border !border-system-border-light bg-system-secondary px-10 py-6 w-fit mx-auto -mt-6 ${secondaryStyle}`}>
                <div className="flex flex-col gap-3 text-center w-[230px]">
                    <div className="flex flex-col gap-0">
                        <TypographyLabel className="font-label-strong">
                            Coming soon:
                        </TypographyLabel>
                        <TypographyBody isStrong={true}>
                            Generate documents
                        </TypographyBody>
                    </div>

                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                        {'Generate reports using your\ntemplates and structure.'}
                    </TypographyLabel>
                </div>

                <div className="flex items-center h-full mx-6">
                    <img className="w-[180px] h-[99px] translate-y-2" src={DocGenHomeImage} />
                </div>
            </div>

            <div className="text-center">
                <TypographyH3>Reports</TypographyH3>
            </div>

            <div className={`flex flex-wrap justify-center gap-x-2 gap-y-4 mx-auto`}>
                <Input
                    className="w-[320px]"
                    placeholder="Search by report name"
                    isSearch={true}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value)
                    }}
                    isCloseVisible={!!searchText}
                    onCloseClick={() => setSearchText('')}
                />

                <Button disabled={createReportLoading} onClick={handleCreateReport}>
                    <div className="flex gap-2">
                        <NotebookPen className="size-6 shrink-0 stroke-[1.5px]" />
                        Create report
                    </div>
                </Button>
            </div>

            <div className="flex flex-col gap-6">
                {createReportError && (
                    <ErrorMessage message="We failed to create a new report, try again shortly" />
                )}

                <ReportList reports={filteredReports} loading={loading} error={error} type='report' />
            </div>

            <NotAvailableDialog open={showNotAvailableDialog} setOpen={(v) => setShowNotAvailableDialog(v)} variant='docgen' />
        </div>
    )
}

export function ReportsPageContainer() {
    const reports = useSelector((state: RootState) => state.docGen.reports);
    const createReport = useSelector((state: RootState) => state.docGen.createReport);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const createReportHandler = async () => {
        dispatch(requestCreateReport({
            requestId: "docgen:create_report",
            params: {},
            timestamp: getTimestamp(),
        }))
    }

    useEffect(() => {
        dispatch(requestReports({
            requestId: "docgen:list_reports_20_0",
            params: {
                limit: 20,
                offset: 0,
            },
            timestamp: getTimestamp(),
        }));
    }, [])

    useEffect(() => {
        if (createReport.data) {
            navigate(`/report/${createReport.data.id}`)

            dispatch(resetCreateReportData())
        }
    }, [createReport.data])

    return (
        <>
            <ReportsPage
                reports={reports.data || []}
                loading={reports.loading}
                error={reports.error}
                createReportLoading={createReport.loading}
                createReportError={createReport.error}
                handleCreateReport={createReportHandler}
            />
        </>
    )
}
