import { useContext } from "react";
import { useSocketQuery } from "../../hooks/useSocketQuery";
import { DESIA_EVENT, ResponseChatList } from "../../types/types";
import { getMessageId, getTimestamp } from "../../utils/utils";
import { ErrorMessage } from "../ErrorMessage";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "../../contexts/AssistantContext";
import { Skeleton } from "../ui/skeleton";
import { TypographyH3 } from "../ui/Typography";
import { Thread } from "./Thread";

export function Threads() {
    const { store, dispatch } = useContext(AssistantStoreContext);
    const { state: { error, loading } } = useSocketQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
            requestId: "list",
            timestamp: getTimestamp(),
            params: {}
        },
        options: {
            manual: false,
            cacheTimeoutMs: 60_000,
            callback: (response) => {
                dispatch({
                    action: ASSISTANT_ACTIONS.FETCH_THREADS,
                    data: response.data as ResponseChatList[]
                })
            }
        }
    });

    return (
        <div
            className="w-full"
        >
            <div
                className="text-center"
            >
                <TypographyH3>Threads</TypographyH3>
            </div>

            {error && (
                <ErrorMessage message={`We failed to list threads, please try again shortly`} className="my-4" />
            )}

            {loading && (
                <div className="py-6 mx-4">
                    <Skeleton className="h-[100px] my-2" />
                    <Skeleton className="h-[100px] my-2" />
                    <Skeleton className="h-[100px] my-2" />
                </div>
            )}
            <div className="py-6">
            {!loading && store.list.sort((a, b) => b.created_at - a.created_at).map((li) => {
                return (
                    <Thread key={getMessageId(li)} thread={li} type='chat' />
                )
            })}
            </div>
        </div>
    )
}
