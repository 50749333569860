import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "./ui/dialog";
import { TypographyBody } from "./ui/Typography";
import { Button } from "./ui/button";

export function WarnOnNavigate({ open, onConfirm, onCancel }: { open?: boolean, onConfirm?: () => void, onCancel?: () => void }) {
    function handleNavigate() {
        onConfirm?.()
    }

    return (
        <Dialog open={open}>
            <DialogContent className="sm:max-w-[425px]" onInteractOutside={() => onCancel?.()}>
                <DialogHeader>
                    <DialogTitle onClose={() => onCancel?.()}>You are about to leave this page</DialogTitle>
                    <DialogDescription className="pb-6">
                        <TypographyBody>
                            Leaving the page will discard the current conversation. Are you sure you want to leave?
                        </TypographyBody>
                    </DialogDescription>
                    <DialogFooter>
                        <DialogClose>
                            <Button variant="secondary" className="w-full" onClick={handleNavigate}>Leave and discard last question</Button>
                        </DialogClose>
                        <DialogClose>
                            <Button className="w-full" onClick={() => onCancel?.()}>
                                Stay on page
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}
