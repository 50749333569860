import "./ChatBox.css";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { useContext, useEffect, useRef, useState } from "react";
import { AssistantAskMode, DossierDetail } from "../../types/types";
import { SourceSelector } from "./SourceSelector";
import { StreamStatus } from "@/contexts/AssistantContext";
import { TypographyBody } from "../ui/Typography";
import LightbulbHead from "@/assets/LightbulbHead";
import Close from "@/assets/Close";
import { useLocation } from "react-router-dom";
import { CustomTooltip } from "../CustomTooltip";
import ArrowRight from "@/assets/ArrowRight";
import { UserContext } from "@/contexts/UserContext";
import texture from '../../assets/bg-texture.png'

export function ExpertModeSwitch({ handleCheckedChange, checked, }: { handleCheckedChange: () => void; checked: boolean }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const location = useLocation()

    useEffect(() => {
        const hasShown = localStorage.getItem('expert_mode_tooltip_shown')

        if (hasShown !== 'true') {
            setShowTooltip(true)
        }
    }, [])

    const updateHasShown = () => {
        localStorage.setItem('expert_mode_tooltip_shown', 'true')
    }

    return (
        <>
            <div data-tooltip-id="expert-mode-tooltip" data-tooltip-place="top" className="rounded-md hover:bg-[#F0F4FA] p-2">
                <div className="flex items-center space-x-2">
                    <Switch id="airplane-mode" onCheckedChange={handleCheckedChange} checked={checked} label="Expert mode" containerClassName="text-system-body" labelClassName="!whitespace-nowrap" />
                </div>
            </div>
            {location.pathname === "/assistant/ask" && (
                <CustomTooltip
                    id="expert-mode-tooltip"
                    clickable={true}
                    isOpen={showTooltip}
                    setIsOpen={(v) => setShowTooltip(v)}
                    afterShow={() => updateHasShown()}
                    children={
                        <div className="max-w-[400px] flex flex-col gap-2 px-1">
                            <div className="flex flex-col gap-1">
                                <div className="flex">
                                    <LightbulbHead className="h-10 w-10 shrink-0" />
                                    <div className="ml-auto cursor-pointer" onClick={() => setShowTooltip(false)}>
                                        <Close className="w-6 h-6 shrink-0" />
                                    </div>
                                </div>
                                <TypographyBody isStrong={true} className="text-system-primary">
                                    Try out the new expert mode
                                </TypographyBody>
                            </div>
                            <TypographyBody className="!text-system-body">
                                Activating Expert mode will provide you with more  accurate answers, though you might have to wait a bit longer
                            </TypographyBody>
                        </div>
                    }
                />
            )}
        </>
    )
}

export type ChatBoxProps = {
    expertModeAvailable: boolean;
    handleSubmit: (args: { message: string, mode: AssistantAskMode }) => void;
    initialMessage?: string;
    additionalControls?: boolean;
    status: StreamStatus;
    isConversation: boolean;
    showBorder: boolean
    dossierDetail?: DossierDetail,
    showFilePreview?: boolean
}
export function ChatBox({ expertModeAvailable, handleSubmit, initialMessage, additionalControls, status, isConversation, dossierDetail, showFilePreview }: ChatBoxProps) {
    const { settings, updateSettings } = useContext(UserContext);
    const [message, setMessage] = useState(initialMessage || "");

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    // @ts-expect-error
    const selectedFiles = settings.assistant.sources.find((v) => v.id === 'internal-search')?.include || []

    const isSourceSelected = settings.assistant.sources.length > 0

    function sendMessage() {
        if (!isSourceSelected) return
        handleSubmit({ message, mode: settings.assistant.mode });
        setMessage("");
    }

    const toggleExpertMode = () => {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    mode: settings.assistant.mode === 'simple' ? 'expert' : 'simple',
                    sources: settings.assistant.mode === 'simple' ? [{
                        id: 'internal-search'
                    }, {
                        id: 'web-search'
                    }] : [...settings.assistant.sources]
                }
            }
        })
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [message])

    const convertToBullets = (input: string) => {
        return input.replace(/^-\s/gm, '• ');
    }

    const containerClass = `${isConversation ? `pb-6 bg-system-surface flex` : ""} mx-auto w-full`

    return (
        <div className={containerClass} style={{ backgroundImage: `url(${texture})` }}>
            <div className="chatbox-container flex flex-col gap-3 p-3 pl-4 w-full flex-grow items-center bg-system-secondary border border-system-border-regular rounded-lg">
                <div className="flex flex-1 max-h-[320px] w-full">
                    <textarea className="max-h-[320px] h-fit font-body placeholder:!text-system-placeholder !resize-none"
                        ref={textareaRef}
                        value={convertToBullets(message)}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.shiftKey !== true) {
                                e.preventDefault();

                                // todo: handle processing states
                                if (status === 'Ready') {
                                    sendMessage();
                                }
                            }
                        }}
                        placeholder="What do you need to know?"
                    />
                </div>
                <div className="flex items-center gap-4 mobile:gap-6 w-full">
                    <div className="w-full">
                        {additionalControls && (
                            <SourceSelector dossierDetail={dossierDetail} showFilePreview={showFilePreview} />
                        )}
                    </div>
                    {expertModeAvailable && selectedFiles.length === 0 && (
                        <div>
                            <ExpertModeSwitch checked={settings.assistant.mode === 'expert'} handleCheckedChange={() => toggleExpertMode()} />
                        </div>
                    )}
                    <div>
                        <Button
                            onClick={sendMessage}
                            disabled={status !== 'Ready' || !isSourceSelected}
                        >
                            <div className="flex gap-2">
                                Ask
                                <ArrowRight className="h-6 w-6 shrink-0 stroke-system-secondary" />
                            </div>
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    )
}
