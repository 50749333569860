import * as Sentry from "@sentry/react";
import { SENTRY_DSN, WEB_SERVER_ENDPOINT, ENVIRONMENT, DISABLE_SENTRY } from "../constants";

export function initializeSentry() {
  if (DISABLE_SENTRY === "true") {
    console.debug(`[desia-web-app] sentry disabled`);
    return;
  }
  
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 20% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", WEB_SERVER_ENDPOINT],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.debug(`[desia-web-app]: sentry initialised`);
}
