"use client"

import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"

import { cn } from "@/shadcn/utils"
import { TypographyBody } from "./Typography"

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  label?: string
  containerClassName?: string
  labelClassName?: string
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, ...props }, ref) => (
  <div className={cn("flex gap-2 items-start", props.containerClassName)}>
    <SwitchPrimitives.Root
      className={cn(
        "peer inline-flex h-5 w-9 my-[2px] shrink-0 cursor-pointer items-center rounded-full border-[3px] border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-system-primary data-[state=unchecked]:bg-system-placeholder",
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          "pointer-events-none block h-3.5 w-3.5 rounded-full bg-system-surface shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0"
        )}
      />
    </SwitchPrimitives.Root>
    {props.label && (
      <TypographyBody className={`whitespace-pre-wrap ${props.labelClassName}`}>
        {props.label}
      </TypographyBody>
    )}
  </div>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
