import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useNavigate } from "react-router";
import { captialise, checkDesiaUser, checkIntegrationFlag, handleLogout } from "../../utils/utils";
import { useContext } from "react";
import { UserContext } from "@/contexts/UserContext";
import { TypographyBody } from "../ui/Typography";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { Button } from "../ui/button";
import { EllipsisVertical } from "lucide-react";

export function UserMenu({ showMenu }: { showMenu: boolean }) {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const ff = useContext(FeatureFlagContext);
  const isDesiaUser = checkDesiaUser(user.user);

  const shouldShowIntegrations = checkIntegrationFlag(ff, user.user)

  const Profile = () => (
    <span
      className="flex items-center gap-2 rounded-lg transition-all hover:text-primary focus:outline-none"
    >
      <img src={user?.user?.picture} className="h-8 w-8 rounded-full" />
      <TypographyBody isStrong={true} className="truncate">{captialise(user.user?.nickname || '')}</TypographyBody>
    </span>
  )

  if (!showMenu) return Profile()

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex gap-2 items-center">
            {Profile()}

            {showMenu && (
              <Button variant='tertiary' size='icon'>
                <EllipsisVertical className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            )}
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >Profile</DropdownMenuItem>
          {shouldShowIntegrations && (
            <>
              <DropdownMenuItem
                onClick={() => {
                  navigate("/integrations");
                }}
              >Integrations
              </DropdownMenuItem>
            </>
          )}
          {isDesiaUser && (
            <DropdownMenuItem
              onClick={() => {
                navigate("/settings");
              }}
            >Settings
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              handleLogout(navigate)
            }}
          >Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>

  )
}