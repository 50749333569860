import { ReactNode, createContext, useEffect, useState } from "react";
import { WEB_SERVER_ENDPOINT } from "../constants";
import { ResAppUser } from "../types/types";
import { UserSettings } from "../types/types";
import { getUserSettings, saveUserSettings } from "../utils/utils";


type UserState = {
    user: ResAppUser | null,
    status: 'loading' | 'error' | 'success',
}
type Handlers = {
    updateSettings: (s: UserSettings) => void;
}
type UserContext = UserState & UserSettings & Handlers;

const initialUserContext = {
    user: null,
    status: 'loading',
    settings: { assistant: { sources: [{ id: "internal-search" }, { id: "web-search" }], parameters: {}, mode: 'simple' } },
    updateSettings: () => { },
} satisfies UserContext;

export const UserContext = createContext<UserContext>(initialUserContext);

function UserProvider(props: { children: ReactNode }) {
    const [user, setUser] = useState<UserState>({ user: null, status: 'loading' });
    const [settings, setSettings] = useState<UserSettings>({ settings: { assistant: { sources: [{ id: "internal-search" }, { id: "web-search" }], parameters: {}, mode: 'simple' } } });

    function updateSettings(s: UserSettings) {
        setSettings(s);
        saveUserSettings(s);
    }

    useEffect(() => {
        function loadUserSettings() {
            const settings = getUserSettings();
            if (settings) {
                setSettings(settings);
            }
        }
        async function getUserInfo() {
            try {
                const res = await fetch(`${WEB_SERVER_ENDPOINT}/api/user/profile`, {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });

                if (res.status !== 200) {
                    throw new Error("We failed to fetch your profile");
                }
                const data: ResAppUser = await res.json();
                setUser(prev => ({
                    ...prev,
                    user: data,
                    status: 'success',
                }));
                loadUserSettings();
            } catch (e) {
                setUser(prev => ({
                    ...prev,
                    user: null,
                    status: 'error'
                }));
                console.error(e);
            }
        }
        getUserInfo();
    }, []);

    return (
        <UserContext.Provider value={{
            user: user.user,
            status: user.status,
            settings: settings.settings,
            updateSettings
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export {
    UserProvider
}
