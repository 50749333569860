import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../ui/chart"
import { TypographyBody } from "../ui/Typography"
import { formatChartDate, formatChartNumber } from "./ResponseChart"
import { ResponseChartData } from "@/types/types"
import { CHART_MIN_TICK_GAP } from "@/constants"

export const ResponseLineChart = ({ parsedData, parsedDataValues, parsedChartConfig, compact }: {
    parsedData: ResponseChartData
    parsedDataValues: {
        [label: string]: string | number;
    }[],
    parsedChartConfig: ChartConfig,
    compact?: boolean
}) => {
    return (
        <div className={`flex flex-col gap-4 p-4 ${compact ? 'mt-8' : 'mt-10'} relative`}>
            <TypographyBody isStrong={true} className={`text-system-primary ${compact && '!font-label-strong'}`}>
                {parsedData.title}
            </TypographyBody>

            <ChartContainer config={parsedChartConfig} className="h-[200px] w-full">
                <LineChart accessibilityLayer data={parsedDataValues} >
                    <CartesianGrid vertical={false} />
                    <YAxis
                        fill="none"
                        stroke="none"
                        domain={['dataMin', 'auto']}
                        minTickGap={CHART_MIN_TICK_GAP}
                        tickMargin={8}
                        tickFormatter={(tick) => {
                            return formatChartNumber(tick)
                        }}
                    />
                    <XAxis
                        dataKey={parsedData.label}
                        tickLine={false}
                        axisLine={false}
                        interval={'preserveStartEnd'}
                        minTickGap={CHART_MIN_TICK_GAP}
                        tickMargin={8}
                        tickFormatter={(tick) => {
                            return formatChartDate(tick)
                        }}
                    />
                    <ChartTooltip content={<ChartTooltipContent labelFormatter={(label) => {
                        return formatChartDate(label)
                    }} />} />

                    {parsedData.values.map((v) => {
                        const color = parsedChartConfig[v].color
                        return <Line
                            key={v}
                            dataKey={v}
                            fill={color}
                            stroke={color}
                            radius={4}
                            opacity={1}
                            type='linear'
                            dot={false}
                            strokeWidth={2}
                            isAnimationActive={false}
                        />
                    })}
                </LineChart>
            </ChartContainer>
        </div>
    )
}