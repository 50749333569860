import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Input } from "../ui/input";
import { DocumentStoreContext } from "@/contexts/DocumentContext";
import { Dossier, DossierDetail, FileStructure, QueryStatus, ResponseDocument, SourceConnector } from "@/types/types";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { TypographyBody } from "../ui/Typography";
import { UserContext } from "@/contexts/UserContext";
import { ChevronLeft, Sparkles, X } from "lucide-react";
import { plural, toggleElement } from "@/utils/utils";
import { FileTable } from "../Integration/FileTable";
import { transformResponseDocuments } from "@/utils/transformResponseDocuments";
import { CustomAlert } from "../CustomAlert";

const DocumentSelectorDialog = ({ trigger, dossier, dossierDetail, initialSelection, type, onSave, open, setOpen }: { trigger?: ReactNode, dossier?: Dossier, dossierDetail?: DossierDetail, initialSelection?: string[], type: 'ask' | 'dossier', onSave?: (ids: string[]) => void, open: boolean, setOpen: (open: boolean) => void }) => {
    const documentContext = useContext(DocumentStoreContext);
    const { settings, updateSettings } = useContext(UserContext);

    const [searchText, setSearchText] = useState('')
    const [tempSources, setTempSources] = useState(settings.assistant.sources)
    const [showSuggestions, setShowSuggestions] = useState(true)
    const [isInitial, setIsInitial] = useState(true)

    const loading = documentContext.store.status === QueryStatus.FETCHING;
    const errorFetching = documentContext.store.status === QueryStatus.ERROR_FETCHING;
    const resources = documentContext.store.data || [];
    const handleListFiles = documentContext.handlers.listDocuments;

    const [elements, setElements] = useState<FileStructure[]>([])
    const [filteredElements, setFilteredElements] = useState<FileStructure[]>([])
    const [selectedElements, setSelectedElements] = useState<FileStructure[]>([])
    const [tempSelectedElements, setTempSelectedElements] = useState<FileStructure[]>([])

    const dossierFileResources = (dossierDetail?.sources.map((v) => {
        return documentContext.store.data?.find((f) => f.document_id === v.resource_id)
    }) || []).filter((v): v is ResponseDocument => Boolean(v))

    const suggestedDocuments = dossierDetail ? dossierFileResources : [...resources].splice(0, 5)

    const suggestedElements = useMemo(() => {
        return transformResponseDocuments(suggestedDocuments)
    }, [suggestedDocuments]).filter((v) => !tempSelectedElements.find((e) => e.internal_element_id === v.internal_element_id))

    useEffect(() => {
        handleListFiles()
        setTempSources(settings.assistant.sources)
    }, [])

    useEffect(() => {
        if (!open) return

        const filteredDocuments = resources.filter((doc) => {
            if (!searchText) { return true }
            const splitText = searchText.toLowerCase().split(' ')
            const parents = doc.document_source_path_treeview || []

            const result = splitText.some((text) => doc.document_name.toLowerCase().includes(text)) || splitText.some((text) => parents.some((v) => v.element_name.toLowerCase().includes(text)))
            return result
        })

        const elements = transformResponseDocuments(filteredDocuments)
        setFilteredElements(elements)
    }, [resources, searchText, open])

    useEffect(() => {
        if (!open) return

        const selected = getSelectedElements(elements)

        setTempSelectedElements(selected)
    }, [elements, open])

    useEffect(() => {
        if (!open) return

        const readyResources = resources.filter((v) => v.document_is_ready_to_use)

        setElements(transformResponseDocuments(readyResources))
    }, [resources, open])

    useEffect(() => {
        if (!open) return
        if (!isInitial) return

        //@ts-expect-error
        const selectedFiles = settings.assistant.sources.find((v) => v.id === 'internal-search')?.include || []

        const selected = type === 'ask' ? elements.filter((v) => selectedFiles.some((f: { id: string, title: string }) => f.id === v.document_id)) : elements.filter((v) => initialSelection?.includes(v.document_id || ''))

        if (tempSelectedElements.length === 0) {
            setTempSelectedElements(() => selected)
        }

        selected.forEach((v) => {
            if (!v.is_included) {
                toggleElement(v, elements, setElements)
            }
        })

        setSelectedElements(() => selected)
    }, [elements, open, isInitial])

    const getSelectedElements = (elements: FileStructure[]): FileStructure[] => {
        let includedElements = []

        includedElements = elements.filter((v) => {
            return v.is_included && v.element_type === 'file'
        })

        return includedElements
    }

    const saveSettings = () => {
        const otherSources = tempSources.filter((v) => v.id !== 'internal-search')
        const newSource: SourceConnector[] = [...otherSources, {
            id: 'internal-search', include: tempSelectedElements.map((v) => {
                return {
                    title: v.element_name,
                    id: v.document_id || '',
                }
            })
        }]

        if (type === 'ask') {
            updateSettings({
                settings: {
                    ...settings,
                    assistant: {
                        ...settings.assistant,
                        sources: newSource.filter((v) => v.id === 'internal-search')
                    }
                }
            })
        }

        const ids = tempSelectedElements.map((v) => v.document_id).filter((v): v is string => Boolean(v))
        onSave?.(ids)
    }

    return (
        <>
            <Dialog open={open} onOpenChange={(v) => {
                setOpen(v)

                setTempSources(settings.assistant.sources)

                if (!v) {
                    setIsInitial(true)
                }
            }}>
                {trigger && (
                    <DialogTrigger asChild>
                        {trigger}
                    </DialogTrigger>
                )}
                <DialogContent className="rounded-md !max-w-[calc(100%-48px)] tablet:!max-w-[951px] max-h-[calc(100vh-48px)] mobile:max-h-[calc(100vh-80px)] overflow-auto mobile:top-20 laptop:!top-30 !translate-y-0">
                    <DialogHeader>
                        <DialogTitle className="text-left">
                            {type === 'ask' ? 'Select files and folders to analyse' : `Select which documents to use in ${dossier?.subject}`}
                        </DialogTitle>
                        <DialogDescription className="font-body text-left whitespace-pre-wrap">
                            {type === 'ask' ? 'You can select up to 10 files to be used as sources for focused analysis.\n Supported file types include .pdf, .csv, .txt, .jpg, .png.' : 'Search for the files you want to add or use the suggestions below.'}
                        </DialogDescription>
                        <div className="flex flex-col gap-10 pt-10">
                            {searchText && tempSelectedElements.length > 0 && (
                                <Button variant="tertiary" onClick={() => setSearchText('')}>
                                    <div className="flex gap-2">
                                        <ChevronLeft className="w-6 h-6 stroke-[1.5px]" />
                                        <TypographyBody isStrong={true}>
                                            {`${tempSelectedElements.length} ${plural('file', tempSelectedElements.length)} selected`}
                                        </TypographyBody>
                                    </div>
                                </Button>
                            )}

                            <div className="mobile:mx-auto">
                                <Input
                                    className="w-full mobile:w-[320px]"
                                    placeholder="Search by file name"
                                    isSearch={true}
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)

                                        if (showSuggestions) {
                                            setShowSuggestions(false)
                                        }
                                    }}
                                    isCloseVisible={!!searchText}
                                    onCloseClick={() => setSearchText('')}
                                />
                            </div>

                            {tempSelectedElements.length > 10 && type === 'ask' && (
                                <CustomAlert
                                    variant='error'
                                    title="You cannot select more than 10 files"
                                    description="Please remove the extra file to continue."
                                />
                            )}

                            {searchText && (
                                <div className="overflow-y-auto">
                                    <FileTable
                                        elements={elements}
                                        shownElements={filteredElements}
                                        resources={resources}
                                        setElements={(v) => {
                                            setElements(v)
                                            setIsInitial(false)
                                        }}
                                        showCheckbox={true}
                                        showHeader={true}
                                        type='ask'
                                        loading={loading}
                                        error={errorFetching}
                                    />
                                </div>
                            )}

                            {!searchText && tempSelectedElements.length > 0 && (
                                <div className="flex flex-col gap-4">
                                    <TypographyBody className="text-system-body">
                                        {`${tempSelectedElements.length} ${plural('file', tempSelectedElements.length)} selected`}
                                    </TypographyBody>

                                    <div className="overflow-y-auto">
                                        <FileTable
                                            elements={elements}
                                            shownElements={tempSelectedElements}
                                            resources={resources}
                                            setElements={(v) => {
                                                setElements(v)
                                                setIsInitial(false)
                                            }}
                                            showCheckbox={true}
                                            showHeader={true}
                                            type='ask'
                                            loading={loading}
                                            error={errorFetching}
                                        />
                                    </div>
                                </div>
                            )}

                            {showSuggestions && !searchText && suggestedElements.length > 0 && (
                                <div className={`flex flex-col gap-6 p-4 bg-[#F5F8FA] rounded-lg ${loading ? 'max-w-full' : '!max-w-[calc(100vw-96px)]'}`}>
                                    <div className="flex gap-2.5 items-center">
                                        {!(type === 'ask' && dossierDetail) && (
                                            <Sparkles className="w-5 h-5 stroke-[1.5px]" />
                                        )}

                                        <TypographyBody className="">{type === 'ask' && dossierDetail ? 'Dossier files' : 'Suggested'}</TypographyBody>

                                        <Button variant={'tertiary'} className="ml-auto" onClick={() => setShowSuggestions(false)}>
                                            <X className="w-6 h-6 stroke-[1.5px]" />
                                        </Button>
                                    </div>
                                    <div className="overflow-y-auto">
                                        <FileTable
                                            elements={elements}
                                            shownElements={suggestedElements}
                                            resources={resources}
                                            setElements={(v) => {
                                                setElements(v)
                                                setIsInitial(false)
                                            }}
                                            showCheckbox={true}
                                            showHeader={true}
                                            type='ask'
                                            loading={loading}
                                            error={errorFetching}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {(selectedElements.length > 0 || tempSelectedElements.length > 0) && (
                            <DialogFooter className="pt-10">
                                <DialogClose>
                                    <Button variant="secondary" className="w-full">Cancel</Button>
                                </DialogClose>
                                {(tempSelectedElements.length < 10 || selectedElements.length > 0 || type !== 'ask') && (
                                    <DialogClose>
                                        <Button className="w-full" onClick={() => saveSettings()}>
                                            Confirm selection
                                        </Button>
                                    </DialogClose>
                                )}
                            </DialogFooter>
                        )}
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DocumentSelectorDialog