import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { TypographyBody } from "../ui/Typography";
import { Button } from "../ui/button";

export function ReportSaveDialog({ open, onConfirm, onCancel }: { open?: boolean, onConfirm?: () => void, onCancel?: () => void }) {
    function handleNavigate() {
        onConfirm?.()
    }

    return (
        <Dialog open={open}>
            <DialogContent className="max-w-fit" onInteractOutside={() => onCancel?.()}>
                <DialogHeader>
                    <DialogTitle onClose={() => onCancel?.()}>You are about to leave and discard unsaved changes</DialogTitle>
                    <DialogDescription>
                        <TypographyBody>
                            Leaving the page will discard any unsaved changes
                        </TypographyBody>
                    </DialogDescription>
                    <DialogFooter className="pt-6">
                        <DialogClose>
                            <Button variant="secondary" className="w-full" onClick={handleNavigate}>Leave and discard changes</Button>
                        </DialogClose>
                        <DialogClose>
                            <Button className="w-full" onClick={() => onCancel?.()}>
                                Stay on page
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}
