import { ChartConfig } from "../ui/chart"
import { ResponseChartData } from "@/types/types"
import { memo, useMemo } from "react"
import { format, isValid } from "date-fns"
import { getLocale } from "@/utils/utils"
import { CustomAlert } from "../CustomAlert"
import { ResponseLineChart } from "./ResponseLineChart"
import { ResponseBarChart } from "./ResponseBarChart"
import { ResponseAreaChart } from "./ResponseAreaChart"
import { ResponsePieChart } from "./ResponsePieChart"

export const formatChartDate = (dateString: string) => {
    const date = new Date(dateString)

    const valid = isValid(date)

    if (valid) {
        const currentDate = new Date()
        const formattedDate = currentDate.getFullYear() === date.getFullYear() ? format(date, 'd MMM') : format(date, 'd MMM yyyy')
        return formattedDate
    }

    return dateString
}

export const formatChartNumber = (label: string) => {
    const number = parseInt(label)

    if (number) {
        return number.toLocaleString(getLocale())
    }

    return label
}

const getChartColor = (index: number): string => {
    const colors = [
        'var(--system-chart-1)',
        'var(--system-chart-2)',
        'var(--system-chart-3)',
        'var(--system-chart-4)',
        'var(--system-chart-5)',
        'var(--system-chart-6)',
        'var(--system-chart-7)',
        'var(--system-chart-8)',
        'var(--system-chart-9)',
        'var(--system-chart-10)',
    ]

    return colors[index % colors.length]
}

export const ResponseChart = memo(({ data, compact }: { data: string, compact?: boolean }) => {
    try {
        const parsedData: ResponseChartData = JSON.parse(data)

        const parsedDataValues = parsedData.data.map((v) => {
            const data: { [label: string]: string | number } = {
                [parsedData.label]: v.label,
            }

            v.value.forEach((v, idx) => {
                data[parsedData.values[idx]] = v
            })

            return data
        })

        const parsedChartConfig: {
            [label: string]: {
                label: string
                color: string
            }
        } = parsedData.values.reduce((a, b, idx) => a = { ...a, [b]: { label: b, color: getChartColor(idx) } }, {}) satisfies ChartConfig

        if (parsedData.chartType === 'Bar') {
            return <ResponseBarChart
                parsedData={parsedData}
                parsedDataValues={parsedDataValues}
                parsedChartConfig={parsedChartConfig}
                compact={compact}
            />
        }

        if (parsedData.chartType === 'Line') {
            return <ResponseLineChart
                parsedData={parsedData}
                parsedDataValues={parsedDataValues}
                parsedChartConfig={parsedChartConfig}
                compact={compact}
            />
        }

        if (parsedData.chartType === 'Area') {
            return <ResponseAreaChart
                parsedData={parsedData}
                parsedDataValues={parsedDataValues}
                parsedChartConfig={parsedChartConfig}
                compact={compact}
            />
        }

        const parsedPieDataValues = parsedData.data.map((v, idx) => {
            const data: { [label: string]: string | number } = {
                [parsedData.label]: v.label,
                fill: getChartColor(idx)
            }

            v.value.forEach((v, idx) => {
                data[parsedData.values[idx]] = v
            })

            return data
        })

        const pieParsedChartConfig: {
            [label: string]: {
                label: string
                color: string
            }
        } = parsedData.data.reduce((a, b, idx) => {
            const newData = { ...a, [b.label]: { label: b.label, color: getChartColor(idx) } }

            return newData
        }, parsedData.values.reduce((a, b) => {
            const newData = { ...a, [b]: { label: b } }

            return newData
        }, {})) satisfies ChartConfig

        if (parsedData.chartType === 'Pie') {
            return <ResponsePieChart
                parsedChartConfig={pieParsedChartConfig}
                parsedData={parsedData}
                parsedDataValues={parsedPieDataValues}
            />
        }

        throw new Error('chart type is invalid')
    } catch {
        return (
            <div className="flex flex-col gap-4 p-4 mt-10">
                <CustomAlert
                    variant='error'
                    title="We could not load the chart at this time"
                    description="We have notified the team and will be fixing the issue soon."
                />
            </div>
        )
    }
})

// container is here to prevent chart rerender
export const ResponseChartContainer = memo(({ data, compact }: { data: string, compact?: boolean }) => {
    const chart = useMemo(() => {
        return <ResponseChart data={data} compact={compact} />
    }, [])

    return chart
})