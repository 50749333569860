import { useContext, useEffect } from "react";
import shortid from "shortid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ASSISTANT_ACTIONS, AssistantStoreContext } from "../../contexts/AssistantContext";
import { checkUserFlag, getTimestamp } from "../../utils/utils";
import { AskTextbox } from "../AskTextbox";
import { NewAsk } from "./NewAsk";
import { AssistantAskMode } from "../../types/types";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";


export function Ask() {
    const navigate = useNavigate();
    const { dispatch } = useContext(AssistantStoreContext);
    const { dossier_id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const initialQuery = searchParams.get("q");
    const initialMode = searchParams.get("mode") as AssistantAskMode;
    const trigger = searchParams.get("trigger");

    const dossiers = useSelector((state: RootState) => state.dossier.dossiers);
    const dossier = dossier_id ? dossiers.data?.find((v) => v.id === dossier_id) : undefined

    function handleSubmit({ message, mode }: { message: string, mode: AssistantAskMode }) {
        const requestId = `new_ask__${shortid()}`;
        dispatch({
            action: ASSISTANT_ACTIONS.NEW_ASK,
            data: {
                requestId,
                question: message,
                timestamp: getTimestamp(),
                mode,
            }
        })
        const encodedQuestion = encodeURIComponent(message);

        if (dossier_id) {
            navigate(`/dossier/${dossier_id}/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${mode}&trigger=true`);
        } else {
            navigate(`/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${mode}&trigger=true`);
        }
    }

    useEffect(() => {
        if (trigger === "true" && initialQuery && initialMode) {
            setSearchParams(next => {
                next.delete("trigger");
                return next;
            })
            handleSubmit({ message: initialQuery, mode: initialMode });
        }
    }, [])

    const isLegacyDesign = checkUserFlag("ui: legacy design system");
    const expertModeAvailable = true;
    const hasSourceSelector = true;

    if (isLegacyDesign) {
        return (
            <div>
                <div>
                    <div>
                        <h1 className="title font-h1">Extract knowledge, and put it to work.</h1>
                        <h2 className="subtitle font-h2">Level up your investment research. In minutes, not hours.</h2>
                    </div>
                    <div className="newchat-asktextbox-container">
                        <AskTextbox
                            placeholder="Ask Desia"
                            handleAsk={(q: string) => {
                                handleSubmit({ message: q, mode: 'simple' });
                            }}
                            canSubmit={true}
                            initialQuestion={initialQuery}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return <NewAsk
        dossier={dossier}
        expertModeAvailable={expertModeAvailable}
        hasSourceSelector={hasSourceSelector}
        handleSubmit={handleSubmit}
    />
}
